// DOCUMENTS
export const DOCUMENTS_TYPES_PAGE_TITLE = 'Filer & dokumenter';
export const DOCUMENTS_TYPES_SECURE_FILES_TITLE = 'Sikre filer';
export const DOCUMENTS_TYPES_SECURE_FILES_TEXT_GUARDIAN = 'Se de sikre filer, som er delt med dig';
export const DOCUMENTS_TYPES_SECURE_FILES_TEXT_EMPLOYEE =
  'Del og gem dine sikre filer, som kræver log in på højere sikkerhedsniveau';
export const DOCUMENTS_TYPES_SECURE_FILES_LINK = 'Gå til Sikre filer';
export const DOCUMENTS_TYPES_COMMON_FILES_TITLE = 'Fælles filer';
export const DOCUMENTS_TYPES_COMMON_FILES_TEXT = 'Se institutionens fælles filer';
export const DOCUMENTS_TYPES_COMMON_FILES_LINK = 'Gå til Fælles filer';
export const DOCUMENTS_TYPES_GOOGLE_FILES_TEXT = 'Del og gem dine filer i Google Drev';
export const DOCUMENTS_TYPES_ONEDRIVE_FILES_TEXT = 'Del og gem dine filer i OneDrive';
export const DOCUMENTS_TYPES_GOOGLE_FILES_LINK = 'Gå til Google Drev';
export const DOCUMENTS_TYPES_ONEDRIVE_FILES_LINK = 'Gå til OneDrive';
export const DOCUMENTS_TYPES_CHOOSE_INSTITUTION = 'Vælg institution';
export const DOCUMENTS_CREATED_SUCCESSFULLY = 'Filen blev oprettet';
export const DOCUMENTS_UPDATED_SUCCESSFULLY = 'Filen blev opdateret';
export const DOCUMENTS_COMMON_FILE_EMPTY_TABLE = 'Der er endnu ikke oprette nogle fælles filer';

export const DOCUMENTS_SHARED_OVERVIEW_SORT = 'Sorter';
export const DOCUMENTS_SHARED_OVERVIEW_FOLDER = 'MAPPE';
export const DOCUMENTS_SHARED_OVERVIEW_DOCUMENT = 'DOK';
export const DOCUMENTS_SHARED_OVERVIEW_DESCRIPTION_TEXT =
  'Filer, du ikke kan vedhæfte er grået ud. Du kan kun vedhæfte filer du har redigeringsrettighed til og som kan deles med de valgte modtagere.';
export const DOCUMENTS_SHARED_OVERVIEW_TABLE_UPDATEDAT = 'Redigeret';
export const DOCUMENTS_SHARED_OVERVIEW_TABLE_GROUP = 'Gruppe';
export const DOCUMENTS_SHARED_OVERVIEW_TABLE_DESCRIPTION = 'Beskrivelse';
export const DOCUMENTS_SHARED_OVERVIEW_TABLE_TITLE = 'Titel';
export const DOCUMENTS_SHARED_OVERVIEW_TABLE_CATEGORY = 'Kategori';
export const DOCUMENTS_SHARED_OVERVIEW_TABLE_ASSOCIATION = 'Barn';
export const DOCUMENTS_SHARED_OVERVIEW_TABLE_EDITED = 'Redigeret';
export const DOCUMENTS_SHARED_OVERVIEW_TABLE_DELETED = 'Slettet';
export const DOCUMENTS_SHARED_OVERVIEW_TABLE_CREATED_DATE = 'Oprettelsesdato';
export const DOCUMENTS_SHARED_OVERVIEW_TABLE_CREATED = 'Oprettet af';
export const DOCUMENTS_SHARED_OVERVIEW_TABLE_INSTITUTION = 'Institution';
export const DOCUMENTS_SHARED_OVERVIEW_TABLE_IMPORTED_FILE = 'Importeret fil';
export const DOCUMENTS_SHARED_OVERVIEW_MENU_EDIT = 'Rediger';
export const DOCUMENTS_SHARED_OVERVIEW_MENU_MOVE = 'Flyt til mappe';
export const DOCUMENTS_SHARED_OVERVIEW_MENU_SHARE = 'Delt med';
export const DOCUMENTS_SHARED_OVERVIEW_MENU_EXPORT = 'Eksport';
export const DOCUMENTS_SHARED_OVERVIEW_MENU_LOCKED_WARNING = 'FEJL';
export const DOCUMENTS_SHARED_OVERVIEW_MENU_REVISION = 'Se revisionshistorik';
export const DOCUMENTS_SHARED_OVERVIEW_MENU_COPY = 'Kopier link';
export const DOCUMENTS_SHARED_DELETE_MENU = 'Slet fil';
export const DOCUMENTS_SHARED_OVERVIEW_MENU_EXPORT_PDF = 'Eksporter PDF';
export const DOCUMENTS_SHARED_OVERVIEW_GUARDIAN_WARNING =
  'Her kan du se sikre filer delt med dig som omhandler dine børn';
export const DOCUMENTS_SHARED_LOCK_DOCUMENT = 'Lås fil';
export const DOCUMENTS_SHARED_UNLOCK_DOCUMENT = 'Lås op for filen';
export const DOCUMENTS_SHARED_DOCUMENT_IS_LOCKED = 'Filen er låst';
export const DOCUMENTS_SHARED_OVERVIEW_MENU_DOWNLOAD = 'Download';
export const DOCUMENTS_SHARED_OVERVIEW_MENU_DOWNLOAD_INTERNAL = 'Vis fil';
export const DOCUMENTS_SHARED_CREATE_DOCUMENT = 'Opret dokument';
export const DOCUMENTS_SHARED_CREATE_DOCUMENT_NOTE = 'Opret note';
export const DOCUMENTS_SHARED_CREATE_FOLDER_LINK = 'Opret mappe';
export const DOCUMENTS_SHARED_IMPORT_FILE_LINK = 'Importer fil';
export const DOCUMENTS_SHARED_WITH_GUARDIAN_MARK = 'Denne fil er delt med forældre';
export const DOCUMENTS_EDIT_FOLDER_TITLE = 'Rediger mappe';
export const DOCUMENTS_LOAD_MORE = 'Hent flere';
export const DOCUMENTS_LOAD_ALL = 'Vis alle';

export const DOCUMENTS_NEW_TITLE = 'OPRET SIKKER FIL';
export const DOCUMENTS_NEW_NOTE_TITLE = 'Opret note';
export const DOCUMENTS_EDIT_TITLE = 'REDIGER SIKKER FIL';
export const DOCUMENTS_REVISION_TITLE = 'VERSION HISTORIK';
export const DOCUMENTS_NEW_LABEL_TITLE = 'Titel';
export const DOCUMENTS_NEW_LABEL_CATEGORIES_AND_TEMPLATES = 'Kategori og skabelon';
export const DOCUMENTS_NEW_LABEL_CATEGORY = 'Kategori';
export const DOCUMENTS_NEW_LABEL_GRUPPE = 'Omhandler';
export const DOCUMENTS_NEW_LABEL_ASSOCIATION = 'Barn';
export const DOCUMENTS_ASSOCIATION_CHILDREN_PLACEHOLDER = 'Vælg børn';
export const DOCUMENTS_NEW_LABEL_TEXT = 'Tekst';
export const DOCUMENTS_NEW_BUTTON_SAVE = 'Gem';
export const DOCUMENTS_NEW_BUTTON_CANCEL = 'Annuller';
export const DOCUMENTS_EMPLOYEES = 'Medarbejdere';
export const DOCUMENTS_ALL_USERS = 'Alle brugere';
export const DOCUMENTS_NEW_CREATED_AT = 'Oprettet {createdDate} kl {createdTime} af {createdBy}';
export const DOCUMENTS_NEW_UPDATED_AT = 'Sidst redigeret {updatedDate} kl {updatedTime} af {updatedBy}';
export const DOCUMENTS_REVISION_UPDATED_AT = 'Redigeret {updatedDate} kl {updatedTime} af {updatedBy}';

export const DOCUMENTS_HISTORY_TITLE = 'Første klasse';
export const DOCUMENTS_HISTORY_EDITION = 'Dato';
export const DOCUMENTS_HISTORY_USER = 'Bruger';
export const DOCUMENTS_HISTORY_CHANGE_TYPE = 'Handling';
export const DOCUMENTS_HISTORY_VERSION = 'Version';
export const DOCUMENTS_HISTORY_TYPE_CREATED = 'Fil oprettet';
export const DOCUMENTS_HISTORY_TYPE_RESTORED = 'Fil gendannet';
export const DOCUMENTS_HISTORY_TYPE_DELETED = 'Fil slettet';
export const DOCUMENTS_HISTORY_TYPE_SHARED = 'Delt med: {userName}';
export const DOCUMENTS_HISTORY_TYPE_UNSHARED = 'Fjernet deling med: {userName}';
export const DOCUMENTS_HISTORY_TYPE_LOCKED = 'Fil låst';
export const DOCUMENTS_HISTORY_TYPE_UNLOCKED = 'Fil låst op';
export const DOCUMENTS_HISTORY_TYPE_EDITED = 'Fil redigeret';
export const DOCUMENTS_HISTORY_TYPE_EXPORTED = 'Eksporteret som pdf';
export const DOCUMENTS_HISTORY_TYPE_IMPLICIT_UNSHARED = 'Fjernet automatisk deling med: {userName}';
export const DOCUMENTS_HISTORY_NOT_AVAILABLE = 'Ikke tilgængelig';
export const DOCUMENTS_HISTORY_TYPE_PERMISSION_ADDED = 'Redigeringsret tildelt: {userName}';
export const DOCUMENTS_HISTORY_TYPE_PERMISSION_REMOVED = 'Redigeringsret fjernet: {userName}';
export const DOCUMENTS_HISTORY_DATE = 'Dato';

export const DOCUMENTS_MOVE_FOLDER_MODAL_MOVE = 'FLYT';
export const DOCUMENTS_CREATE_FOLDER_MODAL_CREATE = 'OPRET';
export const DOCUMENTS_CREATE_FOLDER_MODAL_EDIT = 'RET';

export const DOCUMENTS_SECURE_OVERRIDE_TEXT_1 =
  'Filen indeholder ændringer, der er gemt efter du startede med at redigere. Hvis du fortsætter bliver disse ændringer overskrevet.';
export const DOCUMENTS_SECURE_OVERRIDE_TEXT_2 = 'Er du sikker på, at du vil fortsætte?';
export const DOCUMENTS_SECURE_SEARCH_GROUP = 'Søg efter grupper';
export const DOCUMENTS_SECURE_NO_DOCUMENTS = 'Der er ingen filer, der matcher din søgning';
export const DOCUMENTS_SECURE_NO_DOCUMENTS_GUARDIAN = 'Du har ikke adgang til nogen sikre filer';
export const DOCUMENTS_IMPORT_FILE_TITLE = 'IMPORTER FIL';
export const DOCUMENTS_IMPORT_FILE_TITLE_EDIT = 'REDIGER FIL';
export const DOCUMENTS_IMPORT_FILE_IN_READ_ONLY_MODE_TITLE = 'VIS IMPORTER FIL';
export const DOCUMENTS_FILE_IN_READ_ONLY_MODE_TITLE = 'VIS SIKKER FIL';
export const DOCUMENTS_IMPORT_FILE_LABEL_TITLE = 'Angiv titel';
export const DOCUMENTS_IMPORT_FILE_LABEL_DOCUMENT_CATEGORY = 'Vælg kategori';
export const DOCUMENTS_IMPORT_FILE_LABEL_DOCUMENT_ASSOCIATION = 'Vælg association/elev';
export const DOCUMENTS_IMPORT_FILE_LABEL_DOCUMENT_SHARED_WITH = 'Del med';
export const DOCUMENTS_IMPORT_FILE_LABEL_DOCUMENT_FILE = 'Vedhæft fil';
export const DOCUMENTS_IMPORT_FILE_BUTTON_OK = 'Opret';
export const DOCUMENTS_IMPORT_FILE_LABEL_UPLOAD = 'Vedhæft fil';

export const DOCUMENTS_SHARE_FOLDER_MODAL_CAN_EDIT = 'Kan redigere';
export const DOCUMENTS_SHARE_FOLDER_MODAL_SAVE = 'GEM';
export const DOCUMENTS_SHARE_FOLDER_MODAL_USERPICKER = 'Tilføj bruger eller gruppe';
export const DOCUMENTS_SHARE_FOLDER_MODAL_GROUP_USERPICKER = 'Tilføj gruppe';
export const DOCUMENTS_SHARE_AUTO_SHARING = 'Automatiske delinger';
export const DOCUMENTS_SHARE_SHARING = 'Delinger';

export const DOCUMENTS_IMPORT_FILE_DISCARD_MODAL_BODY =
  'Du lukker nu filen. Dine seneste ændringer gemmes muligvis ikke. Klik på "Annuller" for at gemme alle ændringer.';
export const DOCUMENTS_IMPORT_FILE_DISCARD_MODAL_BODY_1 = 'Dine ændringer vil ikke blive gemt.';
export const DOCUMENTS_IMPORT_FILE_DISCARD_MODAL_BODY_2 = 'Er du sikker på, du vil forlade siden?';

export const DOCUMENTS_IMPORT_FILE_DISCARD_MODAL_BUTTON_OK = 'LUK';

export const DOCUMENTS_BLOCK_COMMUNICATION_BODY_1 =
  'Du kan ikke uploade mediefil. Du er blevet blokeret fra at kommunikere i Aula.';
export const DOCUMENTS_BLOCK_COMMUNICATION_BODY_2 = 'Kontakt institutionens administrator for mere information';
export const DOCUMENTS_ALERT_ASSOCIATION_REQUIRED = 'Du skal vælge et barn';
export const DOCUMENTS_ALERT_CHILDREN_FROM_DIFFERENT_INSTITUTIONS =
  'Det er ikke muligt at vælge børn fra forskellige institutioner og tilknytte dem til den samme sikre fil.';
export const DOCUMENTS_ALERT_GROUP_NOT_IN_INSTITUTION =
  'Den valgte gruppe er ikke tilknyttet din institution. Du kan derfor ikke vælge et barn.';
export const DOCUMENTS_ALERT_FILE_REQUIRED = 'Der skal være tilføjet en fil';
export const DOCUMENTS_ALERT_TITLE_REQUIRED = 'Du skal angive en titel';
export const DOCUMENTS_ALERT_TEMPLATE_REQUIRED = 'Du mangler at angive kategori og skabelon';
export const DOCUMENTS_ALERT_DOCUMENT_CATEGORY_REQUIRED = 'Du mangler at angive kategori.';
export const DOCUMENTS_ALERT_LOCK_DOCUMENT_1 =
  'Du er ved at låse filen {documentName}. Det betyder, at det ikke kan redigeres yderligere.';
export const DOCUMENTS_ALERT_LOCK_DOCUMENT_2 = 'Er du sikker på, at du ønsker at fortsætte?';
export const DOCUMENTS_ALERT_DOWNLOAD_SENSITIVE_FILE_1 =
  'Du er ved at downloade en fil, der kan indeholde følsomme personoplysninger.';
export const DOCUMENTS_ALERT_DOWNLOAD_SENSITIVE_FILE_2 = 'Er du sikker på, at du vil fortsætte?';
export const DOCUMENTS_ALERT_REMOVE_OWN_SHARING =
  'Du er ved at fjerne delingen med dig selv. Hvis du fortsætter vil du ikke længere kunne se eller redigere filen. Er du sikker på, at du vil fortsætte?';
export const DOCUMENTS_ALERT_SHARE_WITH_DIFFERENT_INSTITUTION = 'Brugeren er fra en anden forvaltningsmyndighed';
export const DOCUMENTS_ALERT_EXPORT_PDF_1 =
  'Vær opmærksom på, at du er ved at eksportere en eller flere sikre filer, der kan indeholde følsomme personoplysninger.';
export const DOCUMENTS_ALERT_EXPORT_PDF_2 =
  'Husk at slette de downloadede filer eller sikre dig, at de ligger et sikkert sted, efter de er hentet.';
export const DOCUMENTS_ALERT_LOCKED_DOCUMENT_SELECTED = 'Følgende filer kan ikke deles, da de er låst:';
export const DOCUMENTS_ALERT_NO_EXPORT_RIGHT_DOCUMENT_SELECTED = 'Følgende filer kan ikke eksporteres:';
export const DOCUMENTS_ALERT_EXCEED_MAX_CHILDREN =
  'Du kan ikke oprette filer, der omhandler flere end 50 børn. For at oprette filen skal du fjerne et eller flere omhandlende børn.';
export const DOCUMENTS_ALERT_UNSHAREABLE_GUARDIANS =
  'Filen kunne ikke gemmes, fordi der er valgt ugyldige delinger. Filen kan ikke deles med:';
export const DOCUMENTS_ALERT_UNSHAREABLE_GUARDIANS_2 = 'For at gemme filen skal du fjerne delingerne og prøve igen.';
export const DOCUMENTS_ALERT_CHANGING_TEMPLATE =
  'Dine ændringer bliver ikke gemt, hvis du vælger en ny dokumentskabelon.';
export const DOCUMENTS_ALERT_CHANGING_TEMPLATE_QUESTION = 'Er du sikker på, at du vil vælge en ny dokumentskabelon?';
export const NO_PERMISSION_TO_VIEW_SECURE_DOCUMENT = 'Du har ikke rettigheder til at tilgå Sikre filer';
export const NO_PERMISSION_TO_VIEW_SECURE_SINGLE_DOCUMENT = 'Du har ikke rettigheder til at se den aktuelle fil';
export const DOCUMENTS_TOASTR_COPY_LINK = 'Linket er kopieret';
export const DOCUMENTS_LOCK_SUCCESS = 'Filen blev låst';
export const DOCUMENTS_UNLOCK_SUCCESS = 'Filen blev låst op';
export const HELP_BOX_ASSOCIATION_1 = 'Du skal vælge et eller flere børn, som den sikre fil omhandler.';
export const HELP_BOX_ASSOCIATION_2 = 'Alle medarbejdere i barnets hovedgruppe får adgang til filen.';
export const HELP_BOX_SHARED_WITH_1 =
  'Du kan vælge at dele den sikre fil med medarbejdere og forældre. Du kan dog kun dele filen med barnets forældre. Medarbejdere kan få adgang til den sikre fil på to måder:';
export const HELP_BOX_SHARED_WITH_2 =
  '1) "Automatiske delinger", hvor medarbejderen automatisk får læseadgang til den sikre fil, hvis medarbejderen deler hovedgruppe med barnet. Rettighederne for denne type af deling kan ændres, og delingerne kan fjernes igen. Delingen fjernes automatisk, når medarbejderen ikke længere deler hovedgruppe med barnet.';
export const HELP_BOX_SHARED_WITH_3 =
  '2) "Delinger", hvor en medarbejder med redigeringsrettigheder deler filen med en medarbejder eller forældre. Rettighederne for medarbejderne kan ændres, hvor forældre kun har mulighed for læseadgang. Delingerne kan fjernes igen.';
export const HELP_BOX_SHARED_WITH_4 =
  'En medarbejder kan derfor fremgå under både "Automatiske delinger" og "Delinger".';

export const SECURE_DOCUMENT_ADDED_WAIT =
  'Filen er vedhæftet beskedtråden. Der kan gå op til et minut, før filen er færdigbehandlet og kan åbnes';
export const COMMON_FILE_DATAPOLICY = '(Persondatapolitik)';

export const API_SUCCESS_DELETE_DOCUMENT = 'Filen blev slettet';
export const API_ERROR_DELETE_DOCUMENT = 'Kunne ikke slette filen';
export const API_SUCCESS_RESTORE_DELETED_DOCUMENT = 'Filen blev gendannet.';
export const API_ERROR_RESTORE_DELETED_DOCUMENT = 'Kunne ikke gendanne filen';
export const DELETE_DOCUMENT_CONFIRM = 'Du er ved at slette den sikre fil';
export const DELETE_DOCUMENT_CONFIRM_2 = 'Den sikre fil slettes for alle brugere, der har adgang til den.';
export const DELETE_DOCUMENT_CONFIRM_3 = 'Er du sikker på, at du vil slette den sikre fil?';

export const DOCUMENT_REMOVE_IMPLICIT_SHARING_WARNING_1 =
  'Du har fjernet en eller flere automatiske delinger. Hvis du fortsætter, kan de fjernede personer ikke længere tilgå dokumentet, med mindre de tilføjes under Delinger.';
export const DOCUMENT_REMOVE_IMPLICIT_SHARING_WARNING_2 = 'Er du sikker på at du vil forsætte?';

export const DELETED_SECURE_DOCUMENTS_HEADER = 'Slettede sikre filer';
export const DELETED_SECURE_DOCUMENTS_DESCRIPTION =
  'Her kan du se og gendane alle slettede noter og sikkre filer fra din institution.';
export const DELETED_SECURE_DOCUMENT_RESTORE_WARNING_1 = 'Du er ved at gendanne fil';
export const DELETED_SECURE_DOCUMENT_RESTORE_WARNING_2 = 'Er du sikker, du vil fortsætte?';
export const DELETED_SECURE_DOCUMENTS_TABLE_DELETED_AT = 'Slettet';
