import { DocumentFilterOptionEnum } from '../../../shared/enums/documentFilterOption.enum';
import i18n from '../../../shared/locales/i18n';

interface DocumentFilterOption {
  value?: string;
  label: string;
}

export class DocumentFilter {
  /**
   * get filter options in document overview
   * @param isEmployee
   * @param canShowJournalingOptions
   */
  public static getOptions(isEmployee: boolean, canShowJournalingOptions: boolean): DocumentFilterOption[] {
    const options: DocumentFilterOption[] = [...this.getBaseOptions()];

    if (isEmployee) {
      options.push(...this.getEmployeeOption(canShowJournalingOptions));
    }

    return options;
  }

  /**
   * get base document filter options
   * @private
   */
  private static getBaseOptions(): DocumentFilterOption[] {
    return [
      {
        value: null,
        label: i18n.t('label.all') as string,
      },
      {
        value: DocumentFilterOptionEnum.UNREAD,
        label: i18n.t('label.unread') as string,
      },
    ];
  }

  /**
   * get filterLocked options
   * @private
   */
  private static getEmployeeOption(canShowJournalingOptions: boolean): DocumentFilterOption[] {
    const options: DocumentFilterOption[] = [
      {
        value: DocumentFilterOptionEnum.LOCKED,
        label: i18n.t('label.filter_locked') as string,
      },
    ];

    if (canShowJournalingOptions) {
      options.push(...this.getJournalingStatusOptions());
    }

    return options;
  }

  /**
   * get filter journaling status options
   * @private
   */
  private static getJournalingStatusOptions(): DocumentFilterOption[] {
    return [
      {
        value: DocumentFilterOptionEnum.JOURNALIZED,
        label: i18n.t('document.journalized') as string,
      },
      {
        value: DocumentFilterOptionEnum.BEING_JOURNALIZED,
        label: i18n.t('document.journalization_in_progress') as string,
      },
      {
        value: DocumentFilterOptionEnum.JOURNALIZED_FAILED,
        label: i18n.t('document.journalization_failed') as string,
      },
    ];
  }
}
