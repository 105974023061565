
























import { PropType } from 'vue';
import TableContainer from '../../../shared/libs/table/components/TableContainer.vue';
import TableHeading from '../../../shared/libs/table/components/TableHeading.vue';
import TableRow from '../../../shared/libs/table/components/TableRow.vue';
import TableBody from '../../../shared/libs/table/components/TableBody.vue';
import TableHead from '../../../shared/libs/table/components/TableHead.vue';
import { SecureDocumentModel } from '../../../shared/models/secureDocument.model';
import DocumentPreviewTableRow from './DocumentPreviewTableRow.vue';

export default {
  components: { DocumentPreviewTableRow, TableHead, TableBody, TableRow, TableHeading, TableContainer },
  props: {
    selectedDocuments: {
      type: Array as PropType<SecureDocumentModel[]>,
      default: () => [],
    },
  },
  emits: ['remove'],
  methods: {
    onRemove(documentId) {
      this.$emit('remove', documentId);
    },
  },
};
