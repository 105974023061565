













































import { PropType } from 'vue';
import DocumentPreviewTable from './DocumentPreviewTable.vue';
import { SecureDocumentModel } from '../../../shared/models/secureDocument.model';
import { documentService } from '../../../shared/services/api/document.service';
import { types } from '../../../src_adm/store/types/types.js';
import { mapMutations } from 'vuex';
import { errorSubCodeTypes } from '../../../shared/enums/errorSubCodeTypes.js';
import chunk from 'lodash/chunk';

const EXPORT_TO_ESDH_CHUNK_SIZE = 10;

export default {
  components: { DocumentPreviewTable },
  props: {
    selectedDocuments: {
      type: Array as PropType<SecureDocumentModel[]>,
      default: () => [],
    },
  },
  emits: ['remove', 'sent'],
  data() {
    return {
      isShown: false,
      isLoading: false,
    };
  },
  methods: {
    ...mapMutations({
      setSucessText: types.MUTATE_SUCCESS_TEXT,
      setErrorText: types.MUTATE_ERROR_TEXT,
    }),
    async confirmSending() {
      await this.sendDocuments();
      this.hide();
      this.$refs.confirmSendingDocumentsToEsdhModal.hide();
    },
    cancelSending() {
      this.$refs.confirmSendingDocumentsToEsdhModal.hide();
    },
    onChangeShown(isShown) {
      if (!isShown) {
        this.isShown = false;
      }
    },
    show() {
      this.isShown = true;
    },
    hide() {
      this.isShown = false;
    },
    onRemove(documentId) {
      this.$emit('remove', documentId);
    },
    async sendDocuments() {
      this.isLoading = true;
      const documentIds = this.selectedDocuments.map(doc => doc.id);
      const documentIdsChunks = chunk(documentIds, EXPORT_TO_ESDH_CHUNK_SIZE);
      try {
        const createExportJobPromises = documentIdsChunks.map((documentIds: number[]) =>
          documentService.exportDocumentsToEsdh({ secureDocumentIds: documentIds })
        );
        await Promise.all(createExportJobPromises);
        this.setSucessText('SUCCESS_TOAST_ESDH_DOCUMENT_EXPORT');
        this.$emit('sent');
      } catch (error) {
        if (error.data.status.subCode === errorSubCodeTypes.canNotExportDocumentsFromMultipleDifferentMunicipalities) {
          this.setErrorText('API_ERROR_CAN_NOT_EXPORT_DOCUMENTS_FROM_MULTIPLE_DIFFERENT_MUNICIPALITIES');
        } else {
          this.setErrorText('API_ERROR_GENERAL');
        }
      }
      this.isLoading = false;
    },
  },
};
