

































import { PropType } from 'vue';
import DocumentPreviewTable from './DocumentPreviewTable.vue';
import { SecureDocumentModel } from '../../../shared/models/secureDocument.model';
import { types } from '../../../src_adm/store/types/types.js';
import { mapMutations } from 'vuex';
import { documentService } from '../../../shared/services/api/document.service';
import chunk from 'lodash/chunk';

const REMOVE_OWN_ASSOCIATION_CHUNK_SIZE = 10;

export default {
  components: { DocumentPreviewTable },
  props: {
    selectedDocuments: {
      type: Array as PropType<SecureDocumentModel[]>,
      default: () => [],
    },
  },
  emits: ['remove', 'sent'],
  data() {
    return {
      isShown: false,
      isLoading: false,
    };
  },
  methods: {
    ...mapMutations({
      setSucessText: types.MUTATE_SUCCESS_TEXT,
      setErrorText: types.MUTATE_ERROR_TEXT,
    }),
    async confirmRemoving() {
      await this.removeDocumentAssociation();
      this.hide();
    },
    onChangeShown(isShown) {
      if (!isShown) {
        this.isShown = false;
      }
    },
    show() {
      this.isShown = true;
    },
    hide() {
      this.isShown = false;
    },
    onRemove(documentId) {
      this.$emit('remove', documentId);
    },
    async removeDocumentAssociation() {
      this.isLoading = true;
      const documentIds = this.selectedDocuments.map(doc => doc.id);
      const documentIdsChunks = chunk(documentIds, REMOVE_OWN_ASSOCIATION_CHUNK_SIZE);
      try {
        const removeAssociationPromises = documentIdsChunks.map((documentIds: number[]) =>
          documentService.removeOwnSharings(documentIds)
        );
        await Promise.all(removeAssociationPromises);
        this.setSucessText('SUCCESS_TOAST_DOCUMENT_ASSOCIATION_REMOVED');
        this.$emit('sent', documentIds);
      } catch (error) {
        this.setErrorText('API_ERROR_GENERAL');
      }
      this.isLoading = false;
    },
  },
};
