



















































import { mapActions } from 'vuex';
import { PropType } from 'vue';
import TableRow from '../../../shared/libs/table/components/TableRow.vue';
import TableCell from '../../../shared/libs/table/components/TableCell.vue';
import Icon from '../../../shared/components/Icon.vue';
import AulaButton from '../../../shared/components/AulaButton.vue';
import { SecureDocumentModel } from '../../../shared/models/secureDocument.model';
import { format } from '../../../shared/utils/dateUtil.js';
import { dateFormatEnum } from '../../../shared/enums/dateFormatEnum';
import { documentTypes } from '../../../shared/enums/documentTypes';
import truncate from 'lodash/truncate';
import { InstitutionProfileModel } from '../../../shared/models/institutionProfile.model';
import IconContainer from '../../../shared/components/IconContainer.vue';
import { iconClassEnum } from '../../../shared/enums/iconClassEnum';
import Button from '../../../shared/directives/button.js';
import { types } from '../../store/types/types.js';
import AttachmentList from '../../../shared/components/AttachmentList.vue';

const MAX_SECURE_NOTE_DESCRIPTION_LENGTH = 300;
export default {
  components: { AttachmentList, IconContainer, Icon, AulaButton, TableRow, TableCell },
  directives: {
    Button,
  },
  props: {
    document: {
      type: Object as PropType<SecureDocumentModel>,
      required: true,
    },
  },
  emits: ['remove'],
  data() {
    return {
      isCollapsed: false,
      attachments: [],
      isLoading: false,
    };
  },
  computed: {
    iconClassEnum() {
      return iconClassEnum;
    },
    regardingChildren() {
      if (this.document.associatedInstitutionProfiles.length > 1 && !this.isCollapsed) {
        return this.summaryRegardingChildren;
      }
      const mappedChildren = this.document.associatedInstitutionProfiles.map(
        (child: InstitutionProfileModel) => `${child.name} (${child.metadata})`
      );
      return mappedChildren.map(String).join(', ');
    },
    editedAt() {
      if (!this.document.updatedAt) {
        return '';
      }
      return format(this.document.updatedAt, dateFormatEnum.LONG_DATE_TIME);
    },
    description() {
      switch (this.document.documentType) {
        case documentTypes.NOTE:
          return truncate(this.document.description ?? '', { length: MAX_SECURE_NOTE_DESCRIPTION_LENGTH });
        case documentTypes.RICHDOCUMENT:
          return this.document.templateTitle ?? '';
        default:
          return '';
      }
    },
    summaryRegardingChildren() {
      const firstChild = this.document.associatedInstitutionProfiles[0];
      return this.$t('label.children_summary', {
        name: firstChild.name,
        metadata: firstChild.metadata,
        extra: this.document.associatedInstitutionProfiles.length - 1,
      });
    },
    hasExtraMetadata() {
      return this.document.associatedInstitutionProfiles.length > 1 || this.document.hasMedia;
    },
  },
  methods: {
    ...mapActions({
      loadInternalDocument: types.ACTION_LOAD_INTERNAL_DOCUMENT,
      loadExternalDocument: types.ACTION_LOAD_EXTERNAL_DOCUMENT,
    }),
    onRemove() {
      this.$emit('remove', this.document.id);
    },
    async toggleExtraMetadata() {
      this.isCollapsed = !this.isCollapsed;
      if (this.isCollapsed && this.document.hasMedia && this.attachments.length === 0) {
        await this.loadAttachments();
      }
    },
    async loadAttachments() {
      this.isLoading = true;

      const payload = { id: this.document.id };
      const documentType = this.document.documentType;
      let document = null;

      if (documentType === documentTypes.EXTERNAL) {
        document = await this.loadExternalDocument(payload);
      } else {
        document = await this.loadInternalDocument(payload);
      }

      this.attachments = document.attachments;
      this.isLoading = false;
    },
  },
};
