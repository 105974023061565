import { HttpError } from '../../../src/business/httpError';
import { AxiosError } from 'axios';
import { portal } from '../../assets/plugins/axios/axios';
import { CreateExportJobRequest } from '../../models/requests/createExportJob.request';

class DocumentService {
  public exportDocumentsToEsdh = async (payload: CreateExportJobRequest) => {
    try {
      await portal.post('?method=documents.exportDocumentsToEsdh', payload);
    } catch (error) {
      return Promise.reject(new HttpError(error as AxiosError));
    }
  };

  public removeOwnSharings = async (documentIds: number[]) => {
    try {
      await portal.post('?method=documents.removeOwnSharings', { documentIds });
    } catch (error) {
      return Promise.reject(new HttpError(error as AxiosError));
    }
  };

  public getMaxDocumentsPerExport = async (): Promise<number> => {
    try {
      const { data } = await portal.get('?method=documents.getMaxDocumentsPerExport');
      return data.data;
    } catch (error) {
      return Promise.reject(new HttpError(error as AxiosError));
    }
  };
}

export const documentService = new DocumentService();
